// https://github.com/contentful/rich-text/blob/master/packages/rich-text-react-renderer/src/index.tsx
import {
  Options,
  RenderMark,
  RenderNode,
} from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { Link } from "gatsby"
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from "gatsby-source-contentful/rich-text"
import React, { FunctionComponent } from "react"
import config from "../../../config"

interface RichTextProps {
  field: RenderRichTextData<ContentfulRichTextGatsbyReference>
  options?: Options
}

const RichTextRenderer: FunctionComponent<RichTextProps> = ({
  field,
  options,
}) => {
  const defaultNodeRenderers: RenderNode = {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      return (
        <picture>
          <source
            srcSet={`${
              node.data.target.fields.file[config.locale].url
            }?w=300&h=400&q=75&fm=webp&fit=fill&f=face`}
          />
          <source
            srcSet={`${
              node.data.target.fields.file[config.locale].url
            }?w=300&h=400&q=75&fm=jpg&f=face&fit=fill`}
          />
          <img
            src={`${
              node.data.target.fields.file[config.locale].url
            }?w=300&h=400&q=75&fm=jpg&fit=fill&f=face`}
            title="Source sets are awesome!"
          />
        </picture>
      )
    },
    [INLINES.ASSET_HYPERLINK]: (node) => {
      return `<img src="${node.data.target.fields.file[config.locale].url}"/>`
    },
    [INLINES.ENTRY_HYPERLINK]: (node, next) => (
      <Link
        to={
          node.data.target.fields
            ? `/${node.data.target.fields.slug[config.locale]}`
            : "/404"
        }>
        {next[0]}
      </Link>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    ),
  }

  const defaultMarkRenderers: RenderMark = {}

  options = options || {}

  const RichText = (props: { children: React.ReactNode }) => {
    return <div>{props.children}</div>
  }

  return (
    <RichText>
      {renderRichText(field, {
        renderNode: {
          ...defaultNodeRenderers,
          ...options.renderNode,
        },
        renderMark: {
          ...defaultMarkRenderers,
          ...options.renderMark,
        },
        renderText: options.renderText,
      })}
    </RichText>
  )
}

export default RichTextRenderer
