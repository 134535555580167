import { orderBy } from "lodash"
import { Category, RecipePage } from "../../models"

export class RelatedRecipesFactory {
  recipes: RecipePage[]
  maxRecipes: number
  categories: Category[] | void

  constructor(recipes: RecipePage[], currentRecipeSlug: string) {
    this.recipes = recipes.filter(
      (recipe) => recipe.slug !== currentRecipeSlug.replace(/\//g, "")
    )

    this.maxRecipes = 3
    this.categories = undefined
  }

  setMaxRecipes(m: number): RelatedRecipesFactory {
    this.maxRecipes = m
    return this
  }

  setCategory(categories: Category[]): RelatedRecipesFactory {
    this.categories = categories
    return this
  }

  getRecipes(): RecipePage[] {
    const { categories, recipes, maxRecipes } = this
    const identityMap: {
      [slug: string]: { recipe: RecipePage; points: number }
    } = {}

    if (categories === undefined) {
      console.error(
        "RelatedRecipesFactory: Category not provided, use setCategory()."
      )
      return []
    }

    function getSlug(recipe: RecipePage) {
      return recipe.slug
    }

    function addToMap(recipe: RecipePage) {
      const slug = getSlug(recipe)
      if (identityMap[slug] === undefined) {
        identityMap[slug] = {
          recipe: recipe,
          points: 0,
        }
      }
    }

    function addCategoryPoints(recipe: RecipePage, categories: Category[]) {
      const slug = getSlug(recipe)

      let first = true
      let firstOriginalCategory = true
      if (recipe.categories && categories) {
        recipe.categories.forEach((cat) => {
          categories.forEach((originalCategory) => {
            if (cat.name === originalCategory.name) {
              identityMap[slug].points +=
                first && firstOriginalCategory
                  ? 4
                  : firstOriginalCategory
                  ? 3
                  : first
                  ? 2
                  : 1
              first = false
            }
            firstOriginalCategory = false
          })
        })
      }
    }

    function getIdentityMapAsArray() {
      return Object.keys(identityMap).map((slug) => identityMap[slug])
    }

    for (const recipe of recipes) {
      addToMap(recipe)
      addCategoryPoints(recipe, categories)
    }

    const arrayIdentityMap = getIdentityMapAsArray()
    const similarRecipes = orderBy(arrayIdentityMap, ["points"], ["desc"])

    return similarRecipes.splice(0, maxRecipes).map((x) => x.recipe)
  }
}
