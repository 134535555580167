import { graphql, Link } from "gatsby"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { parse, toSeconds } from "iso8601-duration"
import React, { FunctionComponent, useEffect, useState } from "react"
import Directions from "../components/Directions"
import Ingredients from "../components/Ingredients"
import Layout from "../components/Layout"
import Related from "../components/Related"
import RichTextRenderer from "../components/RichText"
import Seo from "../components/Seo"
import ShareThis from "../components/ShareThis"
import { RecipePage } from "../models"
import * as styles from "./recipe.module.scss"

interface RecipeProps {
  location: Location
  pageContext: {
    id: string
  }
  data: RecipeQuery
}

interface RecipeQuery {
  recipe: RecipePage
}

const RecipeTemplate: FunctionComponent<RecipeProps> = ({
  data,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  pageContext,
  location,
}) => {
  const { recipe }: RecipeQuery = data

  const [recipeState, setRecipe] = useState<RecipePage | undefined>()
  useEffect(() => {
    if (recipeState) {
      const className = "." + recipeState.slug + " img"
      const exists = document.querySelectorAll(className)
      if (exists) {
        exists.forEach((el) => {
          let url = location.href + "?utm_source=pinterest&utm_medium=website"
          if (recipeState.pinterestImage) {
            const src = getSrc(recipeState.pinterestImage.gatsbyImageData)
            el.setAttribute("data-pin-media", "https:" + src)
            url += "&utm_campaign=custom-image"
          } else {
            url += "&utm_campaign=default-image"
          }
          el.setAttribute("data-pin-url", url)
          el.setAttribute("data-pin-description", recipeState.title)
        })
      }
    }
  })

  return (
    <Layout showSearchbox={true} useH1Header={false}>
      <Seo metadata={recipe} />
      <div className={styles.gridContainer}>
        <Link to={`/${recipe.categories[0].slug}`} className={styles.category}>
          {recipe.categories[0].name}
        </Link>
        <div className={styles.sharelink}>
          <ShareThis location={location} title={recipe.title} />
        </div>
        <h1 className={styles.title}>{recipe.title}</h1>
        <GatsbyImage
          alt={recipe.title}
          image={recipe.featuredImage.localFile.childImageSharp.gatsbyImageData}
          className={styles.picture + " " + recipe.slug}
          onLoad={() => setRecipe(recipe)}
        />
        <div className={styles.intro}>
          {recipe.content && <RichTextRenderer field={recipe.content} />}
        </div>
        <div className={styles.characteristics}>
          <ul>
            {toSeconds(parse(recipe.prepTime)) > 0 && (
              <li>
                Voorbereiden
                <strong>
                  {" "}
                  {toSeconds(parse(recipe.prepTime)) / 60} minuten
                </strong>
              </li>
            )}
            <li>
              Bereiden
              <strong> {toSeconds(parse(recipe.cookTime)) / 60} minuten</strong>
            </li>

            <li>
              Porties
              <strong> {recipe.servings}</strong>
            </li>
          </ul>
        </div>
        <div className={styles.ingredients}>
          <h2>Dit heb je nodig:</h2>
          {recipe.ingredients && <Ingredients recipe={recipe} />}
        </div>
        <div className={styles.directions}>
          <h2>Aan de slag!</h2>
          {recipe.directions && <Directions recipe={recipe} />}
        </div>
        <div className={styles.related}>
          <h2>Misschien ook lekker:</h2>
          <Related categories={recipe.categories} />
        </div>
      </div>
    </Layout>
  )
}

export default RecipeTemplate

export const query = graphql`
  query recipes($id: String!, $locale: String!) {
    recipe: contentfulRecipe(id: { eq: $id }, node_locale: { eq: $locale }) {
      ...RecipeMetadata
      ...RecipeData
      course {
        name
      }
      categories {
        name
        slug
      }
      featuredImage: thumbnail {
        ...HeroImage
      }
      pinterestImage {
        ...PinterestImage
      }
    }
  }
`
