import React, { FunctionComponent } from "react"
import LinkIcon from "../../assets/images/link.svg"
import ShareIcon from "../../assets/images/share.svg"
import WhatsappIcon from "../../assets/images/whatsapp.svg"
import useNavigator from "../../hooks/useNavigator"
import * as styles from "./sharethis.module.scss"

interface ShareThisProps {
  location: Location
  title: string
}

const ShareThis: FunctionComponent<ShareThisProps> = ({ location, title }) => {
  const navigatorState = useNavigator()

  const onShareButtonClick = () => {
    if (navigatorState.isShareSupported) {
      navigator.share({
        title: title,
        url: location.href,
      })
    }
  }

  const whatsappShareLink = () => {
    const encTitle = encodeURI(`${title} ${location.href}`)
    const link = `https://api.whatsapp.com/send?text=${encTitle}`

    return link
  }

  const onCopy = (href: string) => {
    const el = document.createElement("textarea")
    el.value = href
    document.body.appendChild(el)
    el.select()
    document.execCommand("copy")
    document.body.removeChild(el)
  }

  return navigatorState.isShareSupported ? (
    <div className={styles.share}>
      <button
        type="button"
        title="Deel dit recept"
        onClick={onShareButtonClick}>
        <ShareIcon className={styles.icon} aria-hidden={true} />
      </button>
    </div>
  ) : (
    <div className={styles.shareManual} tabIndex={0}>
      <div className={styles.shareManualText}>Recept delen</div>
      <a
        className={styles.whatsappButton}
        href={whatsappShareLink()}
        target="_blank"
        rel="nofollow noreferrer"
        title="Deel via Whatsapp">
        <WhatsappIcon className={styles.icon} aria-hidden={true} />
      </a>
      <button
        type="button"
        title="Kopieer de link van dit recept"
        onClick={() => onCopy(location.href)}>
        <LinkIcon className={styles.icon} aria-hidden={true} />
      </button>
    </div>
  )
}

export default ShareThis
