import { Options } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import React, { FunctionComponent } from "react"
import { Recipe } from "../../models"
import RichTextRenderer from "../RichText"

interface IngredientsProps {
  recipe: Recipe
}

const Ingredients: FunctionComponent<IngredientsProps> = ({ recipe }) => {
  const options: Options = {
    renderNode: {
      [BLOCKS.HEADING_3]: (_node, children) => <h3>{children}</h3>,
    },
  }

  return <RichTextRenderer field={recipe.ingredients} options={options} />
}

export default Ingredients
