import { Options } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import React, { FunctionComponent } from "react"
import { Recipe } from "../../models"
import RichTextRenderer from "../RichText"
import * as styles from "./directions.module.scss"

interface DirectionsProps {
  recipe: Recipe
}

const Directions: FunctionComponent<DirectionsProps> = ({ recipe }) => {
  const options: Options = {
    renderNode: {
      [BLOCKS.HEADING_3]: (_node, children) => <h3>{children}</h3>,
      [BLOCKS.QUOTE]: (_node, children) => (
        <div className={styles.quoteContainer}>
          <blockquote className={styles.quote}>{children}</blockquote>
        </div>
      ),
    },
  }

  return <RichTextRenderer field={recipe.directions} options={options} />
}

export default Directions
