// extracted by mini-css-extract-plugin
export var category = "recipe-module--category--0pvcT";
export var characteristics = "recipe-module--characteristics--LQ3HA";
export var directions = "recipe-module--directions--2LCmX";
export var gridContainer = "recipe-module--grid-container--fUW5d";
export var ingredients = "recipe-module--ingredients--kbkrj";
export var intro = "recipe-module--intro--e1gal";
export var picture = "recipe-module--picture--OwLnY";
export var related = "recipe-module--related--Hyv-J";
export var sharelink = "recipe-module--sharelink--n6pNL";
export var title = "recipe-module--title--TuXNr";