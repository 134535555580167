import { useLocation } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"
import React, { FunctionComponent } from "react"
import { Category, QueryResult, RecipePage } from "../../models"
import Grid from "../RecipeGrid"
import { RelatedRecipesFactory } from "./RelatedRecipesFactory"

interface RelatedProps {
  categories: Category[]
}

interface RelatedQuery {
  result: QueryResult
}

export const query = graphql`
  query relatedRecipes {
    result: allContentfulRecipe(sort: { fields: publishDate, order: DESC }) {
      nodes: edges {
        node: node {
          featuredImage: thumbnail {
            ...CardImage
          }
          featuredImageLarge: thumbnail {
            ...CardImageLarge
          }
          ...RecipeMetadata
          categories {
            id
            name
          }
        }
      }
    }
  }
`

const Related: FunctionComponent<RelatedProps> = ({ categories }) => {
  const { result } = useStaticQuery<RelatedQuery>(query)
  const recipes: RecipePage[] = result.nodes.map((x) => x.node as RecipePage)

  const { pathname } = useLocation()
  const relatedRecipes = new RelatedRecipesFactory(recipes, pathname)
    .setMaxRecipes(4)
    .setCategory(categories)
    .getRecipes()

  return <Grid recipes={relatedRecipes}></Grid>
}

export default Related
