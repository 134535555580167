import { useEffect, useState } from "react"

const useNavigator = (): NavigatorState => {
  const [navigatorState, setNavigatorState] =
    useState<NavigatorState>(emptyNavigatorState)

  const webShareSupported = (): boolean => {
    return navigator.share !== undefined
  }

  useEffect(() => {
    const state: NavigatorState = {
      isShareSupported: webShareSupported(),
    }
    setNavigatorState(state)
  }, [])

  return navigatorState
}

export default useNavigator

export interface NavigatorState {
  isShareSupported: boolean
}

const emptyNavigatorState: NavigatorState = {
  isShareSupported: false,
}
